<template>
    <v-row justify="center" class="py-5">
        <v-col style="text-align: center" cols="12" sm="6">
            <!-- <template v-if="!isAuthenticatedReady">
                <h1 class="text-h4 font-weight-light mt-15">Checking authentication status...</h1>
            </template>
            <template v-if="isAuthenticatedReady"> -->
                <p class="mt-15" v-if="isAuthenticated">
                    <!-- TODO: we need to check query parameters, if there's an account id redirect to the dashboard for that account, otherwise show the account selection , and if the user doens't have any accounts then show a message that they need to contact the administrator to be added to an account -->
                    <router-link to="/dashboard">Continue to dashboard</router-link>
                </p>
                <p class="mt-15" v-if="!isAuthenticated">
                    <!-- TODO: we need to give non-authenticated users a chance to "sign up" or "login"; the registration page will check if the server allows open registration (self-service and immediate approval for new users and new accounts), or moderated registration (request it and wait for someone to approve the new user and either a new account or add them to an existing account), or by invitation only (service admin or account admin will send invite link to create user if they didn't have one and join the existing account, user can put in their email to get email resent here but that's it, if they're not on the list they're not proceeding), or closed (not creating new users or accounts right now).  this will cover all the saas and enterprise usages we need. -->
                    <!-- <router-link to="/join">Sign up</router-link> -->
                    <!-- <router-link to="/login">Login</router-link> -->
                    <!-- <v-btn :to="{ name: 'login' }" elevation="4" color="purple white--text">Login</v-btn> -->
                    <v-btn :href="mainWebsiteURL" elevation="4" color="purple white--text">Continue</v-btn>
                </p>
            <!-- </template> -->
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        mainWebsiteURL() {
            return process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://libertybase.io';
        },
    },
    methods: {
        init() {
            const url = this.mainWebsiteURL;
            window.location.href = url;
            // if (this.isAuthenticated) {
            //     // TODO: we need to check query parameters, if there's an account id redirect to the dashboard for that account, otherwise show the account selection , and if the user doens't have any accounts then show a message that they need to contact the administrator to be added to an account
            //     this.$router.push('/dashboard');
            // } else {
            //     // TODO: we need to give non-authenticated users a chance to "sign up" or "login", so don't redirect yet
            //     this.$router.push('/login');
            // }
        },
    },
    watch: {
        isAuthenticatedReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
    },
    mounted() {
        if (this.isAuthenticatedReady) {
            this.init();
        }
    },
};
</script>
